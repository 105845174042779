import type { ReactNode, ReactElement } from 'react';
import { useRef, useEffect } from 'react';

import * as RadixDialog from '@radix-ui/react-dialog';
import { useMountEffect } from '@react-hookz/web';
import styled, { css } from 'styled-components';

import { CDS_TEST_ID_DIALOG_CLOSE_BUTTON } from '../../testIDs';
import { Button } from '../Button/Button';
import { XIconButton } from '../Button/XIconButton';
import { Typography } from '../Typography/Typography';

import type { ButtonProps } from '../Button/Button';

const RadixDialogOverlay = styled(RadixDialog.Overlay)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.capsuleBlue90With80Alpha};
  overflow: auto;
  display: grid;
  place-items: center;
`;

const RadixDialogContent = styled(RadixDialog.Content)<{
  disablePartialSheetMode?: boolean;
  isFullscreenOnMobile?: boolean;
}>`
  position: relative;
  box-sizing: border-box;

  /* NOT MOBILE */
  ${({ theme }) => theme.mediaQueries.small} {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.capsuleBlue50};
    border-radius: ${({ theme }) => theme.radii.r100};
    min-width: 440px;
  }

  /* MOBILE */
  ${({ theme }) => theme.mediaQueries.smallDown} {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.capsuleBlue50};
    margin-top: ${({ theme }) => theme.space.s7};

    ${({ disablePartialSheetMode, theme }) =>
      disablePartialSheetMode !== true
        ? `
          background-color: ${theme.colors.white};
          color: ${theme.colors.capsuleBlue50};
          border-radius: ${theme.radii.r300};
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          position: fixed;
          bottom: 0;
          width: 100%;
          max-height: calc(100vh - ${theme.space.s7});
        `
        : `
          border-radius: ${theme.radii.r100};
        `}

    ${({ disablePartialSheetMode, isFullscreenOnMobile }) =>
      disablePartialSheetMode === true &&
      isFullscreenOnMobile === true &&
      `
        height: 100vh;
        width: 100vw;
        margin: 0;
      `}
  }
`;

const DialogBody = styled('div')<{
  disablePartialSheetMode?: boolean;
  isFullscreenOnMobile?: boolean;
  disableHorizontalPadding?: boolean;
}>`
  font-size: 20px;
  margin: ${({ theme, disableHorizontalPadding }) =>
    `${theme.space.s5} ${disableHorizontalPadding ? 0 : theme.space.s5}`};
  margin-top: 5.5rem;
  height: 100%;
  overflow-y: scroll;

  ${({ theme }) => theme.mediaQueries.smallDown} {
    ${({ disablePartialSheetMode, isFullscreenOnMobile, theme }) =>
      disablePartialSheetMode === true &&
      isFullscreenOnMobile === true &&
      `
        height: calc(100vh - 5.5rem - ${theme.space.s5});
        overflow-y: scroll;
      `}
  }
`;

const DialogTitle = styled(Typography).attrs({ variant: 'heading2' })<{ hasDescription?: boolean }>`
  margin-bottom: ${({ hasDescription, theme }) => (hasDescription ? theme.space.s2 : theme.space.s5)};

  ${({ theme }) => theme.mediaQueries.smallDown} {
    margin-bottom: ${({ hasDescription, theme }) => (hasDescription ? theme.space.s2 : theme.space.s4)};
  }
`;

const DialogCloseButton = styled(XIconButton)`
  position: absolute;
  top: ${({ theme }) => theme.space.s5};
  right: ${({ theme }) => theme.space.s5};
  margin: 0;
  color: ${({ theme }) => theme.colors.capsuleGray50};

  ${({ theme }) => theme.mediaQueries.smallDown} {
    top: ${({ theme }) => theme.space.s5};
    right: ${({ theme }) => theme.space.s5};

    & svg {
      width: 20px;
      height: 20px;
    }
  }
`;

type ConditionalDialogProps =
  | ({
      /**
       * If this is the `state` in `const [state, setState] = useState(false);`, please consider `onOpenChange`
       * as the `setState`.
       *
       * If you imperatively control the dialog's open state, please remember to clean it up. For example,
       * when in a multi-step form whose steps can be traversed, close the modal's state when you change steps.
       *
       * If you control the Dialog manually, you can ONLY control it manually.
       */
      isOpen: boolean;

      triggerButton?: never;
    } & Required<Pick<RadixDialog.DialogProps, 'onOpenChange'>>)
  | {
      isOpen?: never;
      onOpenChange?: never;

      /**
       * While, under the hoods, `@radix-ui/dialog` can handle concurrent imperative and implicit control, we've
       * purposefully structured the props so that you can only choose one. This makes for a less confusing API
       * surface and experience with the dialog.
       */
      triggerButton: ReactElement | ButtonProps;
    };

export type DialogProps =
  | (Omit<RadixDialog.DialogProps, 'defaultOpen' | 'modal' | 'open' | 'onOpenChange'> & {
      title: string;
      description?: string;
      children?: ReactNode;
      disableHorizontalPadding?: boolean;
      disablePartialSheetMode?: boolean;
      isFullscreenOnMobile?: boolean;

      /** Lifecycle method which you may wish to leverage. For example, with analytics. */
      onClose?: () => void;

      /** Lifecycle method which you may wish to leverage. For example, with analytics. */
      onOpen?: () => void;
    }) &
      ConditionalDialogProps;

/**
 * This hook is a round-about way of correctly firing `onOpen` and `onClose` events regardless of
 * if the modal was imperatively or implicitly opened/closed. If the dialog is opened or closed
 * via Dialog.Trigger, that's an implicit event. If it's controlled by the `isOpen` prop from a
 * parent component, it's imperatively controlled.
 */
const useDialogChangeEvent = ({
  isOpen,
  onOpenChange,
  onOpen,
  onClose,
}: Pick<DialogProps, 'isOpen' | 'onOpen' | 'onClose' | 'onOpenChange'>) => {
  const isOpenPreviousState = useRef(false);

  // Initialize global variable correctly.
  useMountEffect(() => {
    isOpenPreviousState.current = isOpen ?? false;
  });

  // Update info on imperative events
  useEffect(() => {
    const isImplicitEvent = isOpen === undefined;
    if (!isImplicitEvent && isOpenPreviousState.current !== isOpen) {
      isOpen ? onOpen?.() : onClose?.();
      isOpenPreviousState.current = isOpen ?? false;
    }
  }, [isOpen, onClose, onOpen]);

  // Fire lifecycle callbacks for imperative events.
  const onChange = (isOpen: boolean) => {
    if (isOpenPreviousState.current === false) onOpen?.();
    if (isOpenPreviousState.current === true) onClose?.();
    console.log({ isOpen });
    onOpenChange?.(isOpen);
    isOpenPreviousState.current = isOpen;
  };

  return onChange;
};

/**
 * This component is a wrapper around `@radix-ui/dialog` that provides an abstracted API and styling.
 *
 * On mobile viewports, a "partial sheet" is rendered.
 */
export const Dialog = (props: DialogProps) => {
  const onDialogOpenStateChange = useDialogChangeEvent({
    isOpen: props.isOpen,
    onOpenChange: props.onOpenChange,
    onOpen: props.onOpen,
    onClose: props.onClose,
  });

  const hasDescription = !!props.description;

  return (
    <RadixDialog.Root
      {...props}
      onOpenChange={onDialogOpenStateChange}
      open={'isOpen' in props ? props.isOpen : undefined}
    >
      {!!props.triggerButton && (
        <RadixDialog.Trigger asChild>
          {'children' in props.triggerButton ? (
            <Button {...props.triggerButton} />
          ) : (
            (props.triggerButton as ReactElement)
          )}
        </RadixDialog.Trigger>
      )}

      <RadixDialog.Portal>
        <RadixDialogOverlay className="cds-dialog-overlay">
          <RadixDialogContent
            className="cds-dialog-content"
            disablePartialSheetMode={props.disablePartialSheetMode}
            isFullscreenOnMobile={props.isFullscreenOnMobile}
          >
            <RadixDialog.Close asChild>
              <DialogCloseButton data-testid={CDS_TEST_ID_DIALOG_CLOSE_BUTTON} helperText="close" />
            </RadixDialog.Close>

            <DialogBody
              disablePartialSheetMode={props.disablePartialSheetMode}
              isFullscreenOnMobile={props.isFullscreenOnMobile}
              disableHorizontalPadding={props.disableHorizontalPadding}
            >
              {props.title && (
                <RadixDialog.Title asChild>
                  <DialogTitle hasDescription={hasDescription}>{props.title}</DialogTitle>
                </RadixDialog.Title>
              )}

              {hasDescription && (
                <RadixDialog.Description asChild>
                  <Typography
                    variant="body"
                    css={css`
                      margin-bottom: ${({ theme }) => theme.space.s6};
                    `}
                  >
                    {props.description}
                  </Typography>
                </RadixDialog.Description>
              )}
              {props.children}
            </DialogBody>
          </RadixDialogContent>
        </RadixDialogOverlay>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
};

export * as RadixDialogPrimitives from '@radix-ui/react-dialog';
